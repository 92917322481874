<template>
    <dialog-box :showDialog="showDialog">
        <div class="box">
            <ul class="box_titles">
                <li class="box_titles_item" @click="handleEvent('changeTab', index)" v-for="(item, index) in contentInfo" :key="index" :class="{'title_active': index == contentInfoIndex}">
                    {{item.title}}
                </li>
            </ul>
            <ul class="box_inputs">
                <p class="box_inputs_tips" v-if="content.tips">{{content.tips}}</p>
                <li class="box_inputs_item" v-for="(item, index) in content.inputItems" :key="index">
                    <div :class="{'has_event': item.eventName}">
                        <img v-if="false" :src="item.icon"/>
                        <input :type="item.type" :placeholder="item.label" v-model="item.value"/>
                    </div>
                    <button v-if="item.eventName" @click="handleEvent(item.eventName)">{{item.eventLabel}}</button>
                </li>
            </ul>
            <button class="box_submit" @click="handleEvent(handleButton.eventName)">{{handleButton.label}}</button>
            <ul v-if="content.tipHandle" class="box_extra">
                <li class="box_extra_item" v-for="(item, index) in tipHandle" :key="index" @click="handleEvent(item.eventName)">{{item.label}}</li>
            </ul>
        </div>
    </dialog-box>
</template>
<script>
import { computed, reactive, toRefs } from 'vue'
export default {
    name: '',
    props: {
        showDialog: {
            type: Boolean,
            default: true
        },
        showExtra: {
            type: Boolean,
            default: true
        },
        contentInfoIndex: {
            type: Number,
            default: 0
        },
        contentInfo: {
            type: Array,
            default: () => {
                return [
                    {
                        title: '卡号登录',
                        inputItems: [
                                    {label: '请输入卡号', value: '0501252', icon: require('../assets/imgs/phone_icon_gray.png')},
                                    {label: '请输入密码', value: '123456', icon: require('../assets/imgs/clock_icon_gray.png')}
                                ],
                        tipHandle: [
                                    {label:'忘记密码', eventName: 'forgetPasword'},
                                    {label: '注册', eventName: 'signIn'}
                                ],
                        submitButton: {label: '登录', eventName: 'login'}
                    },
                    {
                        title: '卡号登录',
                        inputItems: [
                                    {label: '请输入卡号', value: '0501252', icon: require('../assets/imgs/phone_icon_gray.png')},
                                    {label: '请输入密码', value: '214620', icon: require('../assets/imgs/clock_icon_gray.png')}
                                ],
                        tipHandle: [
                                    {label:'忘记密码', eventName: 'forgetPasword'},
                                    {label: '注册', eventName: 'signIn'}
                                ],
                        submitButton: {label: '登录', eventName: 'login'}
                    }
                ]
            }
        }
    },
    setup(props, context) {
        const state= reactive({
        })
        const content = computed(() => {
            return props.contentInfo[props.contentInfoIndex]
        })
        const handleButton = computed(() => {
            return props.contentInfo[props.contentInfoIndex].submitButton
        })
        const tipHandle = computed(() => {
            return props.contentInfo[props.contentInfoIndex].tipHandle
        })

        const titles = props.contentInfo.map(ele => ele.title)

        function handleEvent(eventName, value) {
            console.log(eventName)
            context.emit(eventName, value)
        }
        return {
            ...toRefs(state),
            content,
            titles,
            handleButton,
            tipHandle,
            handleEvent
        }
    },
}
</script>
<style lang='scss' scoped>
.box {
    padding: 0px 20px 20px 20px;
    // font-size: $font-middle;
    &_titles {
        display: flex;
        color: $gray_light;
        font-size: $font-middle;
        border-bottom: solid 1px $line-gray;
        &_item {
            padding-bottom: 10px;
            &:first-child {
                margin-right: 20px;
            }
        }
        .title_active {
            color: #000;
            border-bottom: solid 2.5px;
        }
    }
    &_inputs {
        padding-top: 25px;
        &_tips{
            padding-bottom: $margin-big*2;
            font-size: $font-small;
            line-height: 1.2em;
        }
        &_item {
            position: relative;
            margin-bottom: 15px;
            button {
                word-break: keep-all;
                position: absolute;
                right: 3px;
                top: 50%;
                transform: translateY(-50%);
                color: #696969;
                background: #d6d6d6;
                padding: 8px 0;
                border-radius: $box-radius-small;
                width: 40%;
                margin-left: 10px;
            }
            .has_event {
                width: 50%;
            }
            &>div {
                position: relative;
                border: solid $line-gray 1px;
                padding: 8px 0 8px 10px;
                border-radius: $box-radius-small;
                input {
                    width: auto;
                    padding: 2px 0;
                }
                img {
                    position: absolute;
                    width: 15px;
                    left: 8px;
                    top: 50%;
                    transform: translateY(-50%);
                }

            }
        }
    }
    &_submit {
        width: 100%;
        background: $theme-dark;
        color: #fff;
        text-align: center;
        border-radius: $box-radius-small;
        padding: 8px 0;
        font-size: $font-middle;
    }
    &_extra {
        display: flex;
        justify-content: flex-end;
        padding: 10px 0 15px 0;
        font-size: $font-small;
        li {
            &:last-child {
                margin-left: 0px;
                // color: $theme-dark;
            }
        }
    }
}
</style>