<template>
    <div v-show="show" class="wrap_bg">
        <div class="img_wrap" :style="getBg(isRecive)">
            <div class="img_wrap_button">
                <img :src="isRecive ? imgs[0] : imgs[1]" @click="handleEvent('recieve')"/>
            </div>
            <div class="img_wrap_close" @click="handleEvent('closeDia')"></div>
            <p>{{tip}}</p>
        </div>
    </div>
</template>
<script>
import {reactive, toRefs } from 'vue'

export default {
    name: 'ball',
    props: {
        isRecive: {
            type: Boolean,
            default: true
        },
        show: {
            type: Boolean,
            default: true
        },
        text: {
            type: String, 
            default: ''
        },
        tip: {
            type: String,
            default: ''
        }
    },
    setup (props, context) {
        const state = reactive({
            imgs: [require('../assets/imgs/red_env_recive.gif'), require('../assets/imgs/red_env_recived.png')],
            bgImgs: [require('../assets/imgs/red_env_home.gif'), require('../assets/imgs/red_env_home.png')]
        })
        console.log(props)
        function handleEvent(eventName) {
            context.emit(eventName)
        }
        function getBg(isRecive) {
            return {
                background: `url(${isRecive?state.bgImgs[0] : state.bgImgs[1]}) bottom center no-repeat`,
                backgroundSize: 'contain'
            }
        }
        return {
            ...toRefs(state),
            handleEvent,
            getBg
        }
    }
}
</script>
<style lang='scss' scoped>
.img_wrap {
    // background: url('../assets/imgs/red_env_home.gif') bottom center no-repeat;
    background-size: contain;
    height: 400px;
    position:  relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &_button {
        width: 120px;
        height: 45px;
        position: absolute;
        bottom: 10px;
        img {
            width: 100%;
        }
    }
    &_close {
        display: inline-block;
        width: 30px;
        height: 30px;
        background: url('../assets/imgs/pop_close.png') center center no-repeat;
        background-size: contain;
        position: absolute;
        bottom: -50px;
    }
}
</style>