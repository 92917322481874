<template>
    <ul class='footer'>
        <li 
            class="footer_item" 
            v-for="(item, index) in trueItems()"
            @click="changeIndex(index, item)"
            :key="index">
                <img :src="getImg(index, item)"/>
                <p>{{item.label}}</p>
            </li>
    </ul>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { getFootTab, getRoute, jumpTo } from '../utils/util'
export default {
    name: '',
    props: {
        currentIndex: {
            type: Number,
            default: 0
        },
        useProp: {
            type: Boolean,
            default: false
        },
        footerItems: {
            type: Array,
            default: () => {
                return [
                    {label: '主页', route: '/', img: require('../assets/imgs/icon_foot_home.png'), img2: require('../assets/imgs/icon_foot_home_selected.png')},
                    {label: '多期', route: '/termly', img: require('../assets/imgs/icon_foot_termly.png'), img2: require('../assets/imgs/icon_foot_termly_active.png')},
                    {label: '短信指令', route: '/message-order', img: require('../assets/imgs/icon_foot_open_price.png'), img2: require('../assets/imgs/icon_foot_open_price_selected.png')},
                    {label: '个人中心', route: '/mine', img: require('../assets/imgs/icon_foot_mine.png'), img2: require('../assets/imgs/icon_foot_mine_selected.png')}
                ]
            }
        }
    },
    setup(props, context) {
        const state= reactive({
            index: 0
        })
        const trueItems = () => {
            return props.useProp ? props.footerItems : getFootTab()
        }
        function getIndex() {
            return props.useProp ? props.currentIndex : state.index
        }
        function initData () {
            let route = getRoute()
            route = route.split('/')
            route = route[route.length - 1]
            for (let i = 0, l = trueItems().length; i < l; i ++) {
                if (trueItems()[i].route.indexOf(route) > -1) {
                    state.index = i
                    break
                } else {
                    state.index = 0
                }
            }
        }
        initData()
        function changeIndex(index) {
            if (props.useProp) {
                context.emit('changeIndex', index)
            } else {
                state.index = index
            }
            jumpTo(trueItems()[index].route)
            
        }
        function getImg(_index, item) {
            return _index == getIndex() ? item.img2 : item.img
        }
        return {
            ...toRefs(state),
            changeIndex,
            getImg,
            trueItems,
            getIndex
        }
    },
}
</script>
<style lang='scss' scoped>
.footer {
    display: flex;
    justify-content: space-around;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    box-shadow: 0px 1px 4px 3px #efefef;
    &_item {
        text-align: center;
        padding-top: 3px;
        p {
            line-height: 1em;
            padding-bottom: 5px;
            font-size: $font-less;
        }
    }
    img {
        padding: 2px 0 2px 0;
        height: 25px;
        width: auto;
        display: inline-block;
    }
}
</style>